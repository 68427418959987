export const words = [
  "Qladiator",
  "Titanik",
  "balıq",
  "Albert Einstein",
  "Isaac Newton",
  "Galileo Galilei",
  "xaş",
  "diş",
  "traynik",
  "Marie Curie",
  "Charles Darwin",
  "Leonardo da Vinci",
  "alma",
  "dovşan",
  "pomidor",
  "pişik",
  "limon",
  "yırtıcı",
  "qoyun",
  "naringi",
  "çəkmə",
  "quş",
  "banan",
  "kələm",
  "at",
  "albalı",
  "kartof",
  "xiyar",
  "nar",
  "ördək",
  "portfel",
  "delfin",
  "kök",
  "tülkü",
  "şapka",
  "qarğa",
  "kişi",
  "toyuq",
  "üzüm",
  "qreypfrut",
  "qab",
  "şort",
  "daş",
  "ananas",
  "şam",
  "qutu",
  "it",
  "Xoşqədəm",
  "Elgiz",
  "cücə",
  "əlcək",
  "armud",
  "çay",
  "gilas",
  "yemiş",
  "kirə",
  "özgüç",
  "nəsə",
  "şəfəq",
  "şaftalı",
  "öküz",
  "yelləncək",
  "balıq",
  "məktəb",
  "zebra",
  "çalğı",
  "ev",
  "kivi",
  "kassir",
  "kompyuter",
  "çuğundur",
  "dəftər",
  "badımcan",
  "yazar",
  "qaşıq",
  "süd",
  "göbələk",
  "rəng",
  "kitab",
  "portağal",
  "aslan",
  "kəsici",
  "şüşə",
  "xurma",
  "dondurma",
  "şkaf",
  "qatı",
  "paltar",
  "şalvar",
  "külək",
  "zəif",
  "köynək",
  "gül",
  "dələk",
  "çıraq",
  "paltaryuyan",
  "kağız",
  "müəllim",
  "qarpız",
  "heyva",
  "mandarin",
  "mandarinqa",
  "erik",
  "vişnə",
  "cənnət alma",
  "mango",
  "avokado",
  "şampan",
  "fındıq",
  "arıllı",
  "alyumini",
  "çamadan",
  "zürafə",
  "cırtdan",
  "Napoleon Bonaparte",
  "George Washington",
  "Adolf Hitler",
  "Joseph Stalin",
  "Michael Jackson",
  "William Shakespeare",
  "Steve Jobs",
  "Bill Gates",
  "Elon Musk",
  "Taylor Swift",
  "Cekican",
  "Jan Klod Vandam",
  "Silvestre Stalone",
  "Nizami-metro",
  "Ulduz-metro",
  "Cristiano Ronaldo",
  "Lionel Messi",
  "Usain Bolt",
  "Muhammad Ali",
  "Michael Jordan",
  "LeBron James",
  "Siqaret",
  "Papirus",
  "Kərtənkələ",
  "Kələm",
  "Tısbağa",
  "Divar",
  "Döşək",
  "Yolka",
  "Printer",
  "Kitab",
  "Nar-nömrə",
  "Mount Everest",
  "Mount Kilimanjaro",
  "Top",
  "Mount Fuji",
  "Himalayas",
  "Amazon River",
  "Nile River",
  "Mississippi River",
  "Yangtze River",
  "Pyramids of Giza",
  "Eiffel Tower",
  "Ilham Aliyev",
  "Big Ben",
  "Taj Mahal",
  "Golden Gate Bridge",
  "Louvre Museum",
  "Burj Khalifa",
  "Niagara Falls",
  "Grand Canyon",
  "dəli",
  "komputer",
  "telefon",
  "abb",
  "Basketball",
  "Tennis",
  "Baseball",
  "Golf",
  "Swimming",
  "Velosipet",
  "Boxing",
  "Volleyball",
  "Hockey",
  "Badminton",
  "Surfing",
  "Karate",
  "Wrestling",
  "Australia",
  "Brazil",
  "Canada",
  "Egypt",
  "France",
  "Germany",
  "India",
  "Japan",
  "Kenya",
  "Luxembourg",
  "Mexico",
  "Norway",
  "Portugal",
  "Qatar",
  "Russia",
  "Bayram Nurlu",
  "Samiraldo",
  "Hacı Nuran",
  "Elza Seyidcahan",
  "Spain",
  "Turkey",
  "Ram",
  "Processor",
  "Avtobus",
  "Soğan",
  "Çörək",
  "Kapital Bank",
  "Pasha Bank",
  "Sarımsaq",
  "səhər",
  "günorta",
  "axşam",
  "gecə",
  "saat",
  "dəqiqə",
  "saniyə",
  "gün",
  "həftə",
  "ay",
  "il",
  "ev",
  "otaq",
  "yataq",
  "mətbəx",
  "hamam",
  "qapı",
  "pəncərə",
  "masa",
  "stul",
  "yemək",
  "içmək",
  "süd",
  "çay",
  "qəhvə",
  "su",
  "çörək",
  "meyvə",
  "tərəvəz",
  "ət",
  "balıq",
  "düyü",
  "makaron",
  "yumurta",
  "pendir",
  "yağ",
  "duz",
  "şəkər",
  "dərman",
  "həkim",
  "xəstəxana",
  "aptek",
  "maşın",
  "avtobus",
  "taksi",
  "dəmir yolu",
  "aeroport",
  "təyyarə",
  "bilet",
  "pul",
  "bank",
  "kart",
  "dükkan",
  "bazar",
  "səbət",
  "güzgü",
  "sabun",
  "şampun",
  "diş pastası",
  "diş fırçası",
  "dəsmal",
  "kələm dolması",
  "paltar",
  "köynək",
  "şalvar",
  "paltar",
  "corab",
  "ayaqqabı",
  "gözlük",
  "çətir",
  "çanta",
  "telefon",
  "qayğanaq",
  "öpüş",
  "sevgi",
  "televizor",
  "kamera",
  "foto",
  "məktub",
  "kitab",
  "jurnal",
  "qəzet",
  "qələm",
  "karandaş",
  "kağız",
  "dəftər",
  "universitet",
  "sinif",
  "müəllim",
  "tələbə",
  "Heydər Əliyev",
  "Məmməd Əmin Rəsulzadə",
  "Qara Qarayev",
  "Üzeyir Hacıbəyov",
  "Nizami Gəncəvi",
  "Məhəmməd Füzuli",
  "Müslüm Maqomayev",
  "Tofiq Quliyev",
  "Alim Qasımov",
  "Abdulla Şaiq",
  "Əhməd Cavad",
  "Cəfər Cabbarlı",
  "Çingiz Abdullayev",
  "Əliağa Vahid",
  "Səməd Vurğun",
  "Mirzə Fətəli Axundzadə",
  "Mirzə Ələkbər Sabir",
  "Bəxtiyar Vahabzadə",
  "Xəlil Rza Ulutürk",
  "Bülbül",
  "Zərifə Əliyeva",
  "Lütfi Zadə",
  "Aygün Kazımova",
  "Brilliant Dadaşova",
  "Polad Bülbüloğlu",
  "Əlimərdan bəy Topçubaşov",
  "Tom And Jerry",
  "Donuz",
  "Göy qurşağı",
  "Mac Book",
  "Lenovo",
  "Sarimsaq",
  "Zibil qabı",
  "Pilləkən",
  "3 bacı dolması",
  "Bitcoin",
  "Tufan",
  "Vulkan",
  "Sunami",
  "Kəpənəy",
  "Vedrə",
  "Xəngəl",
  "Qarğa",
  "Qəfədan",
  "Somali",
  "Arabia",
  "Salfetka",
  "Kəmər"
];
